<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="allOrder_content wrap bgw">
					<div class="tab">
						<div :class="{tabActive:navTabNum == v.state}" @click="navTab(v.state)" v-for="(v,i) in tabList" :key="i">{{v.name}}</div>
					</div>
					<div class="box">
						<div class="nameTitle" v-if="navTabNum!=4">
							<div>商品</div>
							<div>数量</div>
							<div>订单状态</div>
							<div>订单金额</div>
							<div>交易操作</div>
						</div>
						<div class="nameTitle" v-if="navTabNum==4">
							<div>商品</div>
							<div>规格</div>
							<div>金额</div>
							<div>配送方式</div>
							<div>当前状态</div>
						</div>
						<div v-if="navTabNum!=4" v-for="(v,i) in OrderList" :key="i" class="goodsBox">
							<div class="goodsTitle">
								<div>
									<span class="time">{{ v.ordertime }}</span>
									<span class="order">订单号: {{ v.out_trade_no }}</span>
								</div>
								<div></div>
								<div> {{v.status_cn}}</div>
							</div>
							<div v-for="(a,b) in v.orderDetailsList" :key="b" class="goods">
								<div>
									<img :src="a.goods_img" alt />
									<p class="two fr">{{a.goods_name}}</p>
								</div>
								<div>{{a.num}}</div>
								<div v-show="b == 0">
									<span @click="GoOrderDetails(a)" class='info'>查看详情</span>
								</div>
								<div v-show="b == 0">¥ {{ v.money }}</div>
								<div v-if="b==0">
									<span @click="tab1=true, selctList = v" v-if="b == 0 && v.status ==0" class='info'>取消订单</span>
									<span @click="GoPay(v)" v-if="b == 0 && v.status ==0" class='info'>立即付款</span>
									<span @click="tab2=true, selctList = v" v-if="b == 0 &&  v.status==2 " class='info'>确认收货</span>
									<span @click="" v-if="b == 0 &&  v.status==2 " class='info' @click="GoOrderDetails(a)" >查看物流</span>
									<span @click="GoEvaluate(a)" v-if="v.status == 3 && a.comment_status==0" :class='{info: true, pingjia: b != 0}'>前往评价</span>
									<span @click="tab3=true, selctList = v" v-if="b == 0 && v.status== 4 || v.status== 5 || v.status== 7" class='info'>删除订单</span>
								</div>

							</div>
						</div>
						<div v-if="navTabNum==4">
							<div v-for="(v,index) in tuikuanlist" :key="index" class="tuikuanbox goods">
								<div class="imgbox">
									<img :src="v.goods_img" alt />
									<div>
										<p class="two fr">{{v.goods_name}}</p>
										<p class="two fr">{{v.num}}*￥{{v.price}}</p>
									</div>
								</div>
								<div>{{v.spec}}/{{v.unit}}</div>
								<div>￥{{v.price*1000*v.num/1000}}</div>
								<div>{{v.posttype_txt}}</div>
								<div>{{v.status_txt}}</div>
							</div>
						</div>
						<div class="page" v-if="showPage">
							<el-pagination background layout="prev, pager, next" :page-size="15" :total="total" @current-change="handleCurrentChange"></el-pagination>
						</div>
					</div>
					<el-dialog title :visible.sync="tab1" width="30%">
						<span>是否取消订单？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="tab1 = false">取 消</el-button>
							<el-button type="primary" @click="cancelOrder()">确 定</el-button>
						</span>
					</el-dialog>

					<el-dialog title :visible.sync="tab2" width="30%">
						<span>是否确认收货？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="tab2 = false">取 消</el-button>
							<el-button type="primary" @click="confirmGoods()">确 定</el-button>
						</span>
					</el-dialog>

					<el-dialog title :visible.sync="tab3" width="30%">
						<span>是否删除订单？</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="tab3 = false">取 消</el-button>
							<el-button type="primary" @click="removeOrder()">确 定</el-button>
						</span>
					</el-dialog>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "../header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部

	import {
		usergetinfo
	} from "@/request/user";
	import {
		getMyOrder
	} from "@/request/index"; //全部订单
	import {
		confirmGetGoods
	} from "@/request/index"; //确认收货
	import {
		delete_order
	} from "@/request/index"; // 删除订单
	import {
		cancel_order
	} from "@/request/index"; //取消订单

	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				navTabNum: -1,
				tabList: [{
						name: "所有订单",
						state: -1
					},
					{
						name: "待付款",
						state: 0
					},
					{
						name: "待发货",
						state: 1
					},
					{
						name: "待收货",
						state: 2
					},
					{
						name: "待评价",
						state: 3
					},
					{
						name: "退款退货",
						state: 4
					}
				],
				tab1: false, //取消订单
				tab2: false, //确认收货订单
				tab3: false, //删除订单
				OrderList: [],
				selctList: [],
				userList: [], // 个人信息
				total: 0,
				showPage: false, // 是否显示分页
				tuikuanlist:[],
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.start();
			// this.state=this.$route.query.state
			let e = this.$route.query.state;
			if (e == undefined) {
				e = -1;
			}
			this.navTabNum = e;
			this.getPageOrder(1)
		},
		methods: {
			start() {
				//   个人信息
				usergetinfo({
					token: this.$token
				}).then(res => {
					this.userList = res.data;
				});
			},
			navTab(e) {
				this.navTabNum = e;
				this.getPageOrder(1)
			},
			// 确认收货
			confirmGoods() {
				this.tab2 = false;
				confirmGetGoods({
					id: this.selctList.id,
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "确认成功",
							type: "success"
						});
					}
				});
			},
			// 删除订单
			removeOrder() {
				this.tab3 = false;
				delete_order({
					id: this.selctList.id,
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "删除成功",
							type: "success"
						});
						this.getPageOrder(1)
					}
				});
			},
			//  取消订单 未支付
			cancelOrder() {
				this.tab1 = false;
				cancel_order({
					id: this.selctList.id,
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "取消成功",
							type: "success"
						});
						this.getPageOrder(1)
					}
				});
			},
			// 去退款
			GoReturn(v) {
				this.$router.push({
					path: "/user/returnGoods?id=" + v.id
				});
			},
			// 去支付
			GoPay(v) {
				this.$router.replace({
					path: `/pay?money=${v.money}&out_trade_no=${v.out_trade_no}&pay_type=alipayScan&state=1`
				});
			},
			// 去详情
			GoOrderDetails(v) {
				this.$router.push({
					path: "/user/OrderDetails?id=" + v.order_id
				});
			},
			GoEvaluate(v) {
				this.$router.push({
					path: `/user/OrderAssess?did=${v.did}&img=${v.goods_img}&name=${v.goods_name}`
				})
			},
			getPageOrder(page) {
				let obj = {
					token: this.$token,
					page
				}
				if (this.navTabNum == 4) {
					// obj.status = '6,7'
					this.$axios.post("/api/goods_order/getRefundOrder", {
						page: 1,
						token: this.$token
					}).then(res => {
						if (res.data.code == 1) {
							this.tuikuanlist = res.data.data.data;
							this.total = res.data.data.total;
							this.showPage = res.data.data.last_page > 1
							console.log(this.tuikuanlist);
							if (this.tuikuanlist.length == 0) {
								this.$message.success("暂无更多订单信息")
							}
						}
					});
					return;
				} else if (this.navTabNum != -1) {
					obj.status = this.navTabNum
				}
				getMyOrder(obj).then(res => {
					if (res.code == 1) {
						this.OrderList = res.data.data;
						this.total = res.data.total;
						this.showPage = res.data.last_page > 1
						if (this.OrderList.length == 0) {
							this.$message.success("暂无更多订单信息")
						}
					}
				})
			},
			handleCurrentChange(page) {
				this.getPageOrder(page)
			}
		}
	};
</script>

<style lang="less" scoped>
	.tuikuanbox{
		border:1px solid #EAEAEA;
		padding: 20px;
		.imgbox{
			display: flex;
			img{
				margin-right: 40px;
			}
			p{
				width: 100% !important;
				margin-bottom: 50px;
			}
		}
	}
	.allOrder_content {
		.tab {
			height: 68px;
			border-bottom: 1px solid #e6e6e6;
			display: flex;
			padding-left: 20px;

			>div {
				padding-left: 16px;
				padding-right: 16px;
				line-height: 68px;
				height: 68px;
				cursor: pointer;
			}

			.tabActive {
				color: #4eeec8;
				border-bottom: 2px solid #4eeec8;
			}
		}

		.box {
			padding-top: 32px;
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 80px;
			position: relative;
			min-height: 400px;
		}

		.page {
			position: absolute;
			width: 100%;
			bottom: 30px;
			text-align: center;
		}

		.nameTitle {
			height: 68px;
			background: rgba(247, 247, 247, 1);
			display: flex;
			margin-bottom: 26px;

			>div {
				text-align: center;
				height: 68px;
				line-height: 68px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(51, 51, 51, 1);
				width: 15%;
			}

			div:nth-of-type(1) {
				width: 40%;
			}
		}

		.goodsBox {
			border: 1px solid #e6e6e6;
			margin-bottom: 20px;
		}

		.goodsTitle {
			height: 64px;
			background: rgba(247, 247, 247, 1);
			display: flex;

			>div {
				text-align: center;
				height: 64px;
				line-height: 64px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(51, 51, 51, 1);
				width: 15%;
			}

			div:nth-of-type(1) {
				width: 40%;
			}

			.time {
				margin-right: 30px;
			}
		}

		.goods {
			margin-top: 12px;
			margin-bottom: 12px;
			padding-left: 16px;
			padding-right: 16px;
			display: flex;
			position: relative;

			>div {
				text-align: center;
				height: 114px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(128, 128, 128, 1);
				width: 15%;
				overflow: hidden;
			}

			p {
				display: inline-block;
				width: 300px;
			}

			img {
				width: 140px;
				height: 114px;
			}

			span {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(78, 238, 200, 1);
				display: block;
				line-height: 30px;
			}

			div:nth-of-type(1) {
				text-align: left;
				width: 40%;
			}

			.info {
				cursor: pointer;
			}
		}
	}

	.pingjia {
		position: absolute;
		right: 70px;
	}
</style>
